@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@200&display=swap');

.message{
    background-color: #cc1d00;
}

.moveIn {
    text-align: center;
}

.noPaddingLow {
    padding-bottom: 0;
}
.white {
    color: white;
}

.newFont {
    font-family: 'Kanit', sans-serif;
}

.btn-outline-danger {
  color: #cc1d00 !important;
  border-color: #cc1d00 !important;
}
.btn-outline-danger:hover {
    color: #cc1d00 !important;
    border-color: #cc1d00 !important;
    background-color: gold !important;
  }