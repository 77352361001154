@import url(https://fonts.googleapis.com/css2?family=Kanit:wght@200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Kanit:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Kanit:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Kanit:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.message{
    background-color: #cc1d00;
}

.moveIn {
    text-align: center;
}

.noPaddingLow {
    padding-bottom: 0;
}
.white {
    color: white;
}

.newFont {
    font-family: 'Kanit', sans-serif;
}

.btn-outline-danger {
  color: #cc1d00 !important;
  border-color: #cc1d00 !important;
}
.btn-outline-danger:hover {
    color: #cc1d00 !important;
    border-color: #cc1d00 !important;
    background-color: gold !important;
  }
@media only screen and (min-width: 991px) {
    .imgSize {
      height: 250px;
    }
  }


  .imgSize:hover {
      opacity: .6;
  }
.line {
    width: 80px;
    height: 2px;
    display: inline-block;
    background-color:#cc1d00;
    margin-bottom: 20px;
}

.fontStyle{
    font-family: 'Kanit', sans-serif;
}
.card {
    width: 100%;
}
.addMargin {
    margin: 1rem;
}
.space {
    margin-top: 40px
}
@media (min-width: 576px){
.parrallax {
    width: 100%;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url(/static/media/jflTruck.e9e96fab.jpg);
    height: 400px;

}
}

@media (max-width: 575px){

    .block{
        width: 100%;
        height: 50px;
        background-color: #cc1d00;
    }
}

.fontStyle {
    font-family: 'Kanit', sans-serif;
}
.aboutMeStyle {
    background-color: white !important;
    border-left: 5px solid #cc1d00;
    border-right: 5px solid #cc1d00;
    border-top: 5px solid #cc1d00;
    margin-bottom: 0px !important; 
    padding-top: 20px;
    
}
.underline {
    text-decoration: underline;
}

@media (min-width: 576px){
    .aboutMeStyle {
        padding: 2rem !important
    }
}
@media (max-width: 576px){
    ul .fontStyle{
        margin: 0 !important;
    }
}

.space {
    margin-right: 10px;

}
a h4{
    color: black;
    transition: all .2s ease-in-out;
}
a h4:hover{
    color: #cc1d00;
    font-size: 30px;
   
}
.top{
    position: relative;
}

.bottom {
    position: absolute;
    top: 20px;
    left: 100px
}

ul {
    list-style-position: inside;
    
}
h3 {
    padding-bottom: 8px;
}

.line {
    width: 80px;
    height: 2px;
    display: inline-block;
    background-color:#cc1d00;
    margin-bottom: 20px;
}

.mikeImage {
    height: 400px;
}
.fontStyle {
    font-family: 'Kanit', sans-serif;
}

.form-check-input:hover{
    cursor: pointer;

}
.required::after {
    color: red;
    content: "*";

}
.line {
    width: 80px;
    height: 2px;
    display: inline-block;
    background-color:#cc1d00;
    margin-bottom: 20px;
}
.iconFooter:hover {
color: #FFD700;
transform: scale(1.2);
}
.iconFooter {
    margin-right: 1rem;
    margin-bottom: 3px;
    color: #dce2e9;
    transition: .2s;
}
.iconText {
    color: #dce2e9;
    transition: all .2s ease-in-out;;

}
.iconText:hover{
    color: #FFD700;
    font-size: 20px;
    transform: scale(1.2);
    


}
a:hover{
    text-decoration: none !important;
}

