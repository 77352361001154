@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@300&display=swap');

.fontStyle {
    font-family: 'Kanit', sans-serif;
}

.form-check-input:hover{
    cursor: pointer;

}
.required::after {
    color: red;
    content: "*";

}
.line {
    width: 80px;
    height: 2px;
    display: inline-block;
    background-color:#cc1d00;
    margin-bottom: 20px;
}