@media (min-width: 576px){
.parrallax {
    width: 100%;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url("../components/photos/jflTruck.jpg");
    height: 400px;

}
}

@media (max-width: 575px){

    .block{
        width: 100%;
        height: 50px;
        background-color: #cc1d00;
    }
}
